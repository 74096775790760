.Column1 {
  width: 30%;
  height: 1000px;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: calc(2px + 2vmin);
  color: white;
  padding-left: 10px;
}

.Column1-header {
  width: 100%;
}
.Column1-body {
  margin-top: 10px;
  width: 100%;
}

.logo {
  width: 100%;
  height: 160px;
}

.contact {
  display: flex;
  justify-content: start;
}
.contact > img {
  width: 30px;
  height: 30px;
}
.mail {
  display: flex;
  justify-content: start;
  align-content: center;
}
.mail-text {
  width: calc(100% - 30px);
  text-wrap: wrap;
  word-wrap: break-word;
}
.mail > img {
  width: 30px;
  height: 30px;
  align-self: center;
}

.hr-clm1 {
  width: 80%;
  border: 3px solid white;
  border-radius: 5px;
}
