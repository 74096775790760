.Column2 {
  margin-left: 40px;
}

.Column2-header {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.Column2-body {
  font-size: calc(5px + 2vmin);
}

.hr-clm2 {
  width: 100%;
  border: 3px solid black;
  border-radius: 5px;
}
